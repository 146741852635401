<template>
    <assessment-rating-modal
        v-if="isShowRiskModal"
        @complete="setRating"
        @close="closeModal()"
    />
    <div @click="openModal()" class="w-24 px-1">
        <rating-widget :actual_rating="value" sm>
            <template v-if="isEdit">
                <span class="block w-4 ml-1"></span>
                <img
                    class="absolute w-4 h-4 -mt-2 top-1/2 right-1.5"
                    src="@/assets/images/icons/edit.svg"
                    alt="Edit icon"
                />
            </template>
        </rating-widget>
    </div>
</template>

<script>
import { ref } from 'vue';
import AssessmentRatingModal from "@/components/common/modals/AssessmentRatingModal.vue";
import RatingWidget from "@/components/common/RatingWidget.vue";

export default {
    emits: ['update'],
    props:["value", "isEdit"],
    components:{
        AssessmentRatingModal,
        RatingWidget,
    },
    setup(props, { emit }) {

        const isShowRiskModal = ref(false);

        function openModal(){
            if(props.isEdit)
                isShowRiskModal.value = true;
        }
        function closeModal(){
            isShowRiskModal.value = false;
        }
        function setRating( rating ){
            emit('update', rating);
            closeModal();
        }
        return {
            isShowRiskModal,
            openModal,
            closeModal,
            setRating,
        }
    },
}
</script>
